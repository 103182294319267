.addwaltshow_box2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0.1rem 0.1rem 0 0;
  border: none;
}
.addwaltshow_box2 .box_makewalt {
  height: 1.1rem;
  color: #5056fd;
  font-size: 0.32rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.addwaltshow_box2 .box_makewalt img {
  width: 24px;
  height: 24px;
  margin-left: 0.4rem;
}
.addwaltshow_box2 .box_makewalt span {
  margin-left: 0.2rem;
}
.addwaltshow_box2 .box_addwalt {
  border-bottom: none;
}
.addwaltshow_box2 .line {
  height: 0.1rem;
  background: #f6f6f8;
}
.addwaltshow_box2 .quxiao {
  border-bottom: none;
  color: #999999;
}
.choice_input {
  width: 1.2rem;
  margin: 0 auto;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  border: none;
  margin-left: 0.1rem;
  background: #ffffff;
}
.choicetoken {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.choicetoken img {
  width: 12px;
  height: 12px;
}
.choicetoken .choice_token {
  width: 84%;
  margin: 0 auto;
  margin-top: 0.2rem;
}
.choicetoken .token_list {
  margin: 3px auto;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-around;
  width: 93%;
}
.choicetoken .token_list .list_box {
  width: 2rem;
  height: 1rem;
  line-height: 1rem;
  border: 1px solid #c6cbd4;
  border-radius: 5px;
  font-size: 0.3rem;
  display: flex;
}
.choicetoken .token_list .list_box img {
  width: 0.5rem;
  height: 0.5rem;
  margin-top: 0.2rem;
  margin-left: 6px;
}
.choicetoken .token_list .list_box span {
  margin-left: 4px;
  font-weight: 600;
}
.choicetoken .token_list .list_box2 {
  width: 2rem;
  height: 1rem;
  line-height: 1rem;
  border: 1px solid #4282f9;
  border-radius: 5px;
  font-size: 0.3rem;
  display: flex;
}
.choicetoken .token_list .list_box2 img {
  width: 0.5rem;
  height: 0.5rem;
  margin-top: 0.2rem;
  margin-left: 6px;
}
.choicetoken .token_list .list_box2 span {
  margin-left: 4px;
  font-weight: 600;
}
.dizhi {
  height: 80px !important;
}
.dizhi .dizhi_span {
  width: 100% !important;
  height: 80px !important;
  word-wrap: break-word;
  word-break: normal;
  line-height: 26px !important;
}
.coin {
  width: 100%;
  height: 100%;
  font-size: 18px;
}
.coin .coin_cen {
  width: 100%;
  height: 100%;
}
.coin .coin_cen .coin_title > div {
  height: 50px;
  display: flex;
  padding: 0 30px;
}
.coin .coin_cen .coin_title > div span {
  display: block;
  height: 40px;
  line-height: 40px;
}
.coin .coin_cen .coin_title > div .avater {
  border-radius: 50%;
  width: 40px;
}
.coin .coin_cen .coin_title > div .avater img {
  width: 100%;
  height: 100%;
}
.coin .coin_cen .coin_title .code {
  width: 100%;
  height: 300px;
  border-top: 1px dashed #ddd;
  padding: 0;
  position: relative;
}
.coin .coin_cen .coin_title .btn {
  display: block;
  margin: 0;
}
.coin .coin_cen .coin_title .btn .btn1,
.coin .coin_cen .coin_title .btn .btn2 {
  margin-top: 10px;
  border: none;
  text-align: center;
  border-radius: 10px;
  color: white;
  background: #00c0ed;
}
.c-nav-bar span,
.c-nav-bar i {
  font-size: 24px !important;
}
