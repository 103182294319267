












































































































































































.addwaltshow_box2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: 4.5rem;
  border-radius: 0.1rem 0.1rem 0 0;
  border: none;
  .box_makewalt {
    height: 1.1rem;
    // text-align: center;
    // line-height: 1.1rem;
    color: #5056fd;
    font-size: 0.32rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 24px;
      height: 24px;
      margin-left: 0.4rem;
    }
    span {
      margin-left: 0.2rem;
    }
  }
  .box_addwalt {
    border-bottom: none;
  }
  .line {
    height: 0.1rem;
    background: #f6f6f8;
  }
  .quxiao {
    border-bottom: none;
    color: #999999;
  }
}
.choice_input {
  width: 1.2rem;
  margin: 0 auto;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  border: none;
  margin-left: 0.1rem;
  background: #ffffff;
}
.choicetoken {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  img {
    width: 12px;
    height: 12px;
  }
  .choice_token {
    width: 84%;
    margin: 0 auto;
    margin-top: 0.2rem;
  }
  .token_list {
    margin: 3px auto;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-around;
    width: 93%;

    .list_box {
      // width: 28%;
      width: 2rem;
      height: 1rem;
      line-height: 1rem;
      border: 1px solid #c6cbd4;
      border-radius: 5px;
      font-size: 0.3rem;
      display: flex;
      img {
        width: 0.5rem;
        height: 0.5rem;
        margin-top: 0.2rem;
        margin-left: 6px;
      }
      span {
        margin-left: 4px;
        font-weight: 600;
      }
    }
    .list_box2 {
      width: 2rem;
      height: 1rem;
      line-height: 1rem;
      border: 1px solid #4282f9;
      border-radius: 5px;
      font-size: 0.3rem;
      display: flex;
      img {
        width: 0.5rem;
        height: 0.5rem;
        margin-top: 0.2rem;
        margin-left: 6px;
      }
      span {
        margin-left: 4px;
        font-weight: 600;
      }
    }
  }
}
.dizhi {
  height: 80px !important;
  .dizhi_span {
    width: 100% !important;
    height: 80px !important;
    word-wrap: break-word;
    word-break: normal;
    line-height: 26px !important;
  }
}
.coin {
  width: 100%;
  height: 100%;
  font-size: 18px;
  .coin_cen {
    width: 100%;
    height: 100%;
    // padding-top: 10px;
    .coin_title {
      > div {
        height: 50px;
        display: flex;
        padding: 0 30px;
        span {
          display: block;
          height: 40px;
          line-height: 40px;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // white-space: nowrap;
        }
        .avater {
          border-radius: 50%;
          width: 40px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .code {
        width: 100%;
        height: 300px;
        border-top: 1px dashed #ddd;
        padding: 0;
        position: relative;
      }
      .btn {
        display: block;
        margin: 0;
        .btn1,
        .btn2 {
          margin-top: 10px;
          border: none;
          text-align: center;
          border-radius: 10px;
          color: white;
          background: #00c0ed;
        }
      }
    }
  }
}
.c-nav-bar {
  span,
  i {
    font-size: 24px !important;
  }
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;